body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

@keyframes scrollRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-120px * 4));
  }
}

.animated-image {
  animation: scrollRight 8s linear infinite;
}


.light_blue {
  background: linear-gradient(135deg, #196EB4 0%, #196EB4 40%, #ffffff89 50%, #196EB4 60%, #196EB4 100%);
  background-repeat: no-repeat;
  background-size: 200% 100%;
  animation: light 4s infinite;
}

.light_orange
{
  background: linear-gradient(135deg, #ff6e00 0%, #ff6e00 40%, #ffffff89 50%, #ff6e00 60%, #ff6e00 100%);
  background-repeat: no-repeat;
  background-size: 200% 100%;
  animation: light 4s infinite;
}

@keyframes light {
  0% {
    background-position: 200% 0; 
  }
  100% {
    background-position: -100% 0; 
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
